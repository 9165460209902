<template>
  <div>
    <v-container >
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            5. Аэрозольные огнетушители (ГОА и АГС)
          </v-card-title>
        </v-col>
        <v-col cols="6">
          <v-card
            class="title font-weight-light pl-10"
            flat
          >
          <div class="d-flex flex-column">
            <p><strong>Используются для тушения:</strong></p>
            <div>
              <v-card-text class="title font-weight-light pb-0">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                твердых веществ;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                ГЖ и ЛВЖ;
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                электроустановок под напряжением.
              </v-card-text>
              <v-card-text class="title font-weight-light">
                <strong>Не подходят для тушения веществ, которым не требуется кислород для горения.</strong>
              </v-card-text>
            </div>
            <v-card flat class="ma-5 ptm-img-relative">
                <v-img
                  lazy-src="img/material/exp-4-4.png"
                  max-width="80%"
                  src="img/material/exp-4-4.png"
                  class="ma-1"
                ></v-img>
              </v-card>
          </div>

          </v-card>
        </v-col>
                <v-col cols="6">
          <v-card class="mb-4">
            <v-tabs color="red" grow v-model="tab2">
              <v-tab>Принцип действия</v-tab>
              <v-tab>Правила обращения</v-tab>
            </v-tabs>
          </v-card>
          <v-card class="pa-2 pb-5">
            <v-tabs-items v-model="tab2">
              <v-tab-item>
                <v-card-title class="">
                Огнетушащий состав :
                </v-card-title>
                <v-card-text class="title font-weight-light pb-1">
                 парообразующие вещества на основе бромистого этила, хладона или их смеси
                </v-card-text>
                <v-card-title class="">
                Воздействие огнетушащих веществ:
                </v-card-title>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  останавливают цепные химические реакции, возникающие в результате горения;
                </v-card-text>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                 осаживаются на поверхностях горящих предметов и образовывают плотную пленку, которая препятствует доступу кислорода.
                </v-card-text>
                <v-card-title class="">
                Способ подачи в зависимости от конструкции<br> огнетушителя:
                </v-card-title>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                 вытеснение порошкообразного мелкодисперсного огнетушащего вещества нейтральным газом;
                </v-card-text>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                 спрессованное твердое вещество, выделяющее при возгорании большое количество аэрозоля.
                </v-card-text>
              </v-tab-item>
              <v-tab-item>
                <v-card class="pa-5">

                <v-card-title class="">
                  Правила обращения:
                </v-card-title>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  применять в помещении только после эвакуации, поскольку концентрация ОТВ (Огнетушащее вещество) и его состав токсичны;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  большинство подобных огнетушителей не могут быть остановлены после активации;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  при тушении электроустановок заряд подавать порциями;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  начинать тушить с ближнего края очага;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  при горении масла заряд подавать сверху;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  огонь в нише гасить сверху.
                </v-card-text>
                </v-card>

              </v-tab-item>
            </v-tabs-items>
          </v-card>

        </v-col>

      </v-row>
      <Hint></Hint>
    </v-container>

  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab1: null,
    tab2: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less">
.ptm-act-tab-class {
  background-color: #FFCDD2;
  }
.ptm-img-relative {
  position: relative;
}
.ptm-ovp-1 {
  position: absolute;
  top: 12%;
  left: 7%;
}
.ptm-ovp-2 {
  position: absolute;
  top: 72%;
  left: 8%;
}
.ptm-ovp-3 {
  position: absolute;
  top: 22%;
  left: 75%;
}
.ptm-ovp-4 {
  position: absolute;
  top: 64%;
  left: 75%;
}
.ptm-ovp-5 {
  position: absolute;
  top: 81%;
  left: 75%;
}
</style>
